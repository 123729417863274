<template>
  <div>
   <CRow>
    <CCol lg="12">
      <CNavbar light color="light" class="px-0">
      <h4> Subject List</h4>
      <CForm inline>
          <b-input-group size="sm">
            <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
            ></b-form-input>
          </b-input-group>
          <CButton color="dark" size="sm" class="border-0">
            <CIcon name="cil-search" size="sm" />
          </CButton>&nbsp;&nbsp;
          <CButton
            color="outline-dark "
            class="my-2 my-sm-0 btn-out-dashed"
            type="submit"
            size="sm"
            @click="next_page"
            v-if="user != '8de886dd-12e5-4b02-b812-187639513779'"
          >+ Add New</CButton
          >
      </CForm>
      </CNavbar>
      <div class="table-wrapper py-3">
        <CRow>
          <CCol col="12" xl="12">
             <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="xs"
              show-empty
              small
              @filtered="onFiltered"
              >
               <template #cell(action)="data">
                  <CButton color="primary" size="sm" class="border-0" @click="get_unique_data(data.item)">
                    View
                  </CButton>
                </template>
            </b-table>
          </CCol>
        </CRow>
      </div>
      </CCol>
    </CRow>
    <b-row>
      <b-col sm="2">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
      <b-col  offset-sm="7"  sm="3">
          <b-form-group horizontal label="Per page" label-size="sm" :label-cols="6" >
          <b-form-select :options="pageOptions" v-model="perPage" size="sm"/>
          </b-form-group>
      </b-col>
  </b-row>
  </div>
</template>

<script>
import AddSubject from "./AddSubject";
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;

export default {
  name: "SubjectList",
  data() {
    return {
      items: [],
      filter:"",
      user:"",
      fields: [
        { key: "subject_name",label: "Subject" },
        { key: "class_id",label: "Class" },
        'action'
      ],
      activePage: 1,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    get_unique_data(row){
      this.$router.push({ name: "teacher_study_material",params:{id:row}});
    },
    next_page(){
       this.$router.push({ name: "AddSubject"});
    },
    get_data(){
       var promise = apis.list_Subjects().then( response => {
          this.items = response.data;
      }).catch( errror => {

      })
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
  components:{
    AddSubject
  },
  created(){
    this.get_data();
    this.user = localStorage.getItem('user_role_id');
  }
};
</script>

<style></style>
